// Intro.js

import React, { useEffect, useRef } from "react";


const Intro = () => {
  const introTextRef = useRef(null);

  useEffect(() => {
    // Add a class to the intro-text div to trigger the animation
    const introText = introTextRef.current;
    if (introText) {
      introText.classList.add("animate-from-left");
    }
  }, []);

  return (
    <div className="intro-container">
        <div className="intro-text" ref={introTextRef}>
          <p style={{ fontSize: "2.5rem", fontWeight: "bold" }}>
          Experience the Crystal One
          </p>
          <p>
            Your Gateway to Success in Technology,
            Trading, and Trade Advisory.
          </p>
        </div>
      </div>
  );
};

export default Intro;
