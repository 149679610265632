// About.js

import React from "react";

const About = () => {
  return (
    <section id="about" className="about-section">
      <div className="about-container">
        <div className="about-top">
          <h1 style={{ textAlign: "center", margin: "2rem", fontSize: "3rem" }}>
            About Crystal One
          </h1>
          <h2 style={{ textAlign: "center" }}>
            Welcome to Crystal One: Pioneering Proprietary Trading
          </h2>

          <p style={{ textAlign: "center", marginTop: "2rem" }}>
            Crystal One is a proprietary trading firm focused on raising capital
            for Separately Managed Accounts. 
          </p>
          <p style={{ textAlign: "center" }}>
            Discover our innovative approach to navigating global markets using
            cutting-edge technology and sophisticated trading strategies. The
            firm is dedicated to pushing the boundaries of trading and offering
            unparalleled solutions in a dynamic landscape. Explore our platform
            and unlock a world of limitless opportunities for success in
            trading.
          </p>
        </div>

        <div className="card-container">
          {/* Card 1: Our Expertise */}
          <div className="card">
            <h3>Our Expertise</h3>
            <p>
              Our expertise knows no bounds, and we are wholeheartedly dedicated
              to assisting businesses in flourishing within the intricate
              landscape of technology and trading. Crystal One excels in
              delivering innovative solutions and sophisticated trading
              algorithms.
            </p>
          </div>

          {/* Card 2: Our Diverse Portfolio */}

          {/* Card 3: Why Choose Crystal One? */}
          <div className="card">
            <h3>Why Crystal One?</h3>
            <p>
              We recognize that success in these fields demands a profound
              understanding of the challenges and opportunities at hand. We're
              not content with merely providing services; we go the extra mile
              to tailor our offerings to your unique requirements. This ensures
              that every solution we deliver is finely tuned to your specific
              goals and objectives.
            </p>
          </div>
        </div>
        <div className="additional-content">
          <p>
            Crystal One is your trusted partner on your journey towards success.
            With us by your side, you can confidently navigate the ever-changing
            landscape of technology and commerce, secure in the knowledge that
            you have a dedicated team of experts poised to empower your success.
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;
