import React from "react";
import Header from "./components/Header";
import Intro from "./components/Intro";
import About from "./components/About";
// import Services from "./components/Services";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import "./App.css";

const App = () => {
  return (
    <div>
      <Header />
      <Intro />
      <About />
      <Contact />
      <Footer />
    </div>
  );
};

export default App;
