import React from "react";

const Footer = () => {
  return (
    <footer style={{ color: "#C8B568", padding: "10px", textAlign: "center" }}>
      &copy; 2023 Crystal One Inc. All rights reserved.
    </footer>
  );
};

export default Footer;
