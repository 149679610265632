import React from "react";
import Logo from "../components/images/logo.png";
import { Link, animateScroll as scroll } from "react-scroll";
import "../navbar.css";

const Header = () => {
  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <nav className="navbar">
        <div class="content">
          <div className="navbar-logo" onClick={scrollToTop}>
            <img src={Logo} alt="Logo" className="logo-image" />
          </div>
          <div className="navbar-tagline">
            <small style={{ fontWeight: "600" }}>
            Your Partner in Success for Technology and Trading
            </small>
          </div>
        </div>
        <div className="navbar-content">
          <div className="navbar-links">
            <Link
              to="about"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              About
            </Link>
            {/* <Link
              to="services"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              Services
            </Link> */}
            <Link
              to="contact"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              Contact Us
            </Link>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
