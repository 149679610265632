// Contact.js

import React from "react";

const Contact = () => {
  return (
    <section id="contact" className="contact-section">
      <div className="contact-container">
        <h1>Contact Us</h1>
        <div className="contact-card">
          <form
            action="https://formsubmit.co/info@crystal1.ca"
            method="post"
          >
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input type="text" id="name" name="name" required />
            </div>

            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input type="email" id="email" name="email" required />
            </div>

            <div className="form-group">
              <label htmlFor="phone">Phone Number</label>
              <input type="" id="phone" name="phone" />
            </div>

            <div className="form-group">
              <label htmlFor="message">Inquiry</label>
              <textarea id="message" name="message" rows="4" required />
            </div>
            <div className="form-group">
              <label htmlFor="message">Reference</label>
              <textarea id="message" name="message" rows="4" required />
            </div>
            <div className="form-group">
              <button type="submit">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
